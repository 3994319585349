<template>
  <div class="dandelion-mobile-header">
    <img class="logo" src="/static/img/dandelion-store/sociollaX-logo.png" alt="sociolla X" />
    <img class="back" src="/static/img/dandelion-store/arrow-back.svg" alt="Back" @click="goBack()" />
  </div>
</template>
<script>
export default {
  methods: {
    goBack() {
      this.$router.push('/dandelion-store');
    },
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 721px) {
  .dandelion-mobile-header {
    display: none !important;
  }
}
.dandelion-mobile-header {
  width: 100%;
  position: sticky;
  top: 0;
  height: 55px;
  background: #fff;
  .logo {
    width: 135px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .back {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
