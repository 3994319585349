<template>
  <div class="product-not-found">
    <dandelion-navigation-mobile />
    <div class="center-container-mobile">
      <span>Sorry bestie, <br />kami tidak menemukan apa yang kamu cari.</span>
    </div>
    <div class="center-container">
      <div class="content">
        <img class="logo" src="/static/img/dandelion-store/sociollaX-logo.png" alt="sociolla X" />
        <div class="sorry-text">
          <span>Sorry bestie, <br />kami tidak menemukan apa yang kamu cari.</span>
          <div class="separator" />
          <span class="big-sorry-text">Butuh bantuan? <br />Silahkan hubungi Beauty Advisor kami.</span>
        </div>
      </div>
      <div v-if="banner_url" class="banner">
        <img :src="banner_url" />
      </div>
    </div>
  </div>
</template>
<script>
import DandelionNavigationMobile from './dandelion-navigation-mobile.vue';

export default {
  components: {
    DandelionNavigationMobile,
  },
  computed: {
    banner_url() {
      return this.$store.state.dandelionStore.banners?.pdp;
    },
  },
  mounted() {
    this.$store.dispatch('getDandelionStoreBanner', 'store_admin_price_checker_pdp');
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 768px) {
  .content,
  .banner {
    display: none !important;
  }
  .product-not-found {
    margin: 0 !important;
    background: #fff !important;
  }
  .center-container-mobile {
    background: rgba(237, 6, 119, 1);
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    box-shadow: 0px 8px 10px -4px rgba(181, 97, 108, 0.4);
    border-radius: 15px;
    padding: 50px 20px;
    margin: auto 40px;
    display: block !important;
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    span {
      font-family: 'brandontext-medium';
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
  }
}

.center-container-mobile {
  display: none;
}
.product-not-found {
  position: relative;
  margin: 0 108px;
  height: 100vh;
  gap: 44px;
  .center-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    gap: 25px;
  }
  .content {
    width: 40vw;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(255, 237, 244, 1);
    box-shadow: 0px 10px 10px 0px rgba(144, 49, 87, 0.3);
    border-radius: 15px;
    padding: 32px 27px;
    .logo {
      width: 245px;
      height: 44px;
    }
    .sorry-text {
      background: rgba(237, 6, 119, 1);
      box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
      box-shadow: 0px 8px 10px -4px rgba(181, 97, 108, 0.4);
      border-radius: 15px;
      padding: 20px;
      width: 100%;
      margin-top: 32px;
      height: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      gap: 30px;
      span {
        font-family: 'brandontext-medium';
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        color: #ffffff;
      }
      .separator {
        content: ' ';
        height: 1px;
        border-bottom: 1px dashed;
        color: white;
      }
      .big-sorry-text {
        font-size: 16px !important;
        line-height: 18px !important;
      }
    }
  }
  .banner {
    width: 30vw;
    aspect-ratio: 4/3;
    background: grey;
    border-radius: 15px;
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
      -o-object-fit: fill;
      border-radius: 15px;
    }
  }
}
</style>
